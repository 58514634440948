import {
	disableParameters,
	enableParameters,
	disableVisibilities,
	enableVisibilities,
	disableShowStatusNames,
	enableShowStatusNames
} from '@/api/pmparameter';

const disableParameter = {
	id: 'disableParameter',
	selectionType: 'multiple',
	label: 'pmparameter.actions.disableParameter',
	functionality: 'UPDATE_PMPARAMETER',
	checkAvailability: function (parameter) {
		return parameter && parameter.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmparameter.actions.disableParameter');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmparameterid: registries[i].pmparameterid
			});
		}

		const data = await disableParameters(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableParameter = {
	id: 'enableParameter',
	selectionType: 'multiple',
	label: 'pmparameter.actions.enableParameter',
	functionality: 'UPDATE_PMPARAMETER',
	checkAvailability: function (parameter) {
		return parameter && parameter.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmparameter.actions.enableParameter');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmparameterid: registries[i].pmparameterid
			});
		}

		const data = await enableParameters(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const disableVisibility = {
	id: 'disableVisibility',
	selectionType: 'multiple',
	label: 'pmparameter.actions.disableVisibility',
	functionality: 'UPDATE_PMPARAMETER',
	checkAvailability: function (parameter) {
		return parameter && parameter.visible === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmparameter.actions.disableVisibility');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmparameterid: registries[i].pmparameterid
			});
		}

		const data = await disableVisibilities(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableVisibility = {
	id: 'enableVisibility',
	selectionType: 'multiple',
	label: 'pmparameter.actions.enableVisibility',
	functionality: 'UPDATE_PMPARAMETER',
	checkAvailability: function (parameter) {
		return parameter && parameter.visible === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmparameter.actions.enableVisibility');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmparameterid: registries[i].pmparameterid
			});
		}

		const data = await enableVisibilities(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const disableShowStatusName = {
	id: 'disableShowStatusName',
	selectionType: 'multiple',
	label: 'pmparameter.actions.disableShowStatusName',
	functionality: 'UPDATE_PMPARAMETER',
	checkAvailability: function (parameter) {
		return parameter && parameter.showstatusname === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmparameter.actions.disableShowStatusName');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmparameterid: registries[i].pmparameterid
			});
		}

		const data = await disableShowStatusNames(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableShowStatusName = {
	id: 'enableShowStatusName',
	selectionType: 'multiple',
	label: 'pmparameter.actions.enableShowStatusName',
	functionality: 'UPDATE_PMPARAMETER',
	checkAvailability: function (parameter) {
		return parameter && parameter.showstatusname === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmparameter.actions.enableShowStatusName');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmparameterid: registries[i].pmparameterid
			});
		}

		const data = await enableShowStatusNames(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};
export default {
	actions: [disableParameter, enableParameter, disableVisibility, enableVisibility, disableShowStatusName, enableShowStatusName]
};
