<template>
	<div v-if="isSuperAdmin">
		<pui-datatable
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:externalFilter="externalFilter"
			:showDeleteBtn="true"
			:actions="actions"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
		></pui-datatable>
	</div>
	<div v-else>
		<pui-datatable
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:externalFilter="externalFilter"
			:showDeleteBtn="true"
			:actions="actions"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import ParameterActions from './PmParameterActions';

//import constants from '@/utils/constants';
import { isSuperAdmin } from '../../api/common';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'pmparametergrid',
	data() {
		return {
			model: 'pmparameter',
			actions: ParameterActions.actions,
			masterDetailColumnVisibles: {
				name: true,
				acronym: true,
				elasticacronym: true,
				unitname: true,
				symbol: true,
				deliveryperiod: true,
				timeunitname: true,
				chartname: true,
				visible: true,
				showstatusname: true,
				iscalculatedparameter: true,
				disabled: true,
				organizationname: true
			},
			// TEST COLUMNS HIDDEN
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				visible: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				showstatusname: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				}
			},
			isSuperAdmin: false
		};
	},
	computed: {},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	mounted() {
		// eslint-disable-next-line no-unused-vars

		if (!this.isSuperAdmin) {
			this.externalFilter = {
				groupOp: 'or',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }],
				groups: []
			};
		}
	},
	methods: {}
};
</script>

<style lang="postcss" scoped></style>
