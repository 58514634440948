// Aqui haremos las llamadas a todos los endpoints que cuelguen de eventtype
import Pui9HttpRequests from '@Pui9Requests';

export async function disableParameters(parameter) {
	const controller = '/pmparameter/disableParameters';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		parameter,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableParameters(parameter) {
	const controller = '/pmparameter/enableParameters';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		parameter,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function disableVisibilities(parameter) {
	const controller = '/pmparameter/disableVisibilities';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		parameter,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableVisibilities(parameter) {
	const controller = '/pmparameter/enableVisibilities';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		parameter,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function disableShowStatusNames(parameter) {
	const controller = '/pmparameter/disableShowStatusNames';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		parameter,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableShowStatusNames(parameter) {
	const controller = '/pmparameter/enableShowStatusNames';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		parameter,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}
